import {useState} from 'react'
import './App.css';

//Import Pages
import AboutUs from './pages/AboutUs';

// Global Style
import GlobalStyle from './components/GlobalStyle';

function App() {

  const [menu_class, setMenuClass] = useState('menu toggle')
  const [showcase_class, setShowcaseClass] = useState('showcase')
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const toggleMenu = () => {
    if (!isMenuClicked) {
      setMenuClass('menu toggle active')
      setShowcaseClass('showcase active')
      setIsMenuClicked(true)      
    }
    else {
      setMenuClass('menu toggle')
      setShowcaseClass('showcase')
      setIsMenuClicked(false)
    }
  }

  //uncomment to work on this - app section
  // return (
  //   <div className='App'>
  //     <GlobalStyle />
  //     <AboutUs />
  //   </div>
  // )

  return (
    <div className="app">
    <div className={showcase_class}>
    <div className="header">
      <h2 className="logo">Open Dojo</h2>
      <div className={menu_class} onClick={toggleMenu}></div>
    </div>

    <video src="https://res.cloudinary.com/drimdvpsc/video/upload/v1672567698/judoBow_tdtwci.mp4" autoPlay muted></video>
    <div className="overlay"></div>
    <div className="text">
    <h2>The Whole World is Your Dojo </h2> 
          <p>Coming soon. In the meantime, check out the blog</p>
      <button href="#">Blog</button>
    </div>
    
    
    {/* <ul class="social">
      <li><a href="#"><img src="https://i.ibb.co/x7P24fL/facebook.png"></a></li>
      <li><a href="#"><img src="https://i.ibb.co/Wnxq2Nq/twitter.png"></a></li>
      <li><a href="#"><img src="https://i.ibb.co/ySwtH4B/instagram.png"></a></li>
    </ul> */}
  
  </div>
  <div className="menu">
    <ul>
      <li><button className='button' href="#" target="_blank" rel="noopener noreferrer">Home</button></li>
      <li><button className='button' href="#" target="_blank" rel="noopener noreferrer">News</button></li>
      {/* <li><button className='button' href="#" target="_blank" rel="noopener noreferrer">Destination</button></li> */}
      <li><button className='button' href="#" target="_blank" rel="noopener noreferrer">Blog</button></li>
      <li><button className='button' href="#" target="_blank" rel="noopener noreferrer">Contact</button></li>
    </ul>
  </div>


     
    </div>

  );
}

export default App;
